import React from "react";
import Grid from "@mui/material/Grid";

function Summary() {
  return (
    <div className="masterDiv lg:px-32 md:px-12 px-6 lg:py-32 md:py-20 py-12 font-manrope">
      <Grid container spacing={{ lg: 10, md: 6, sm: 4, xs: 2 }}>
        <Grid item xs={12} lg={6}>
          <h1 className="font-bold text-customOrange text-left lg:text-right text-4xl md:text-5xl lg:text-7xl">
            A Hobby Sharing Marketplace
          </h1>
        </Grid>
        <Grid item xs={12} lg={6}>
          <p className="mt-2 font-semibold text-black text-xl md:text-3xl lg:text-4xl">
            Imagine Airbnb or Turo but for hobby equipment! Skip the rental
            counter and Amazon packaging!
          </p>
          <ul
            className="ml-5 mt-2 md:mt-4 lg:mt-8 font-base text-black text-base md:text-xl lg:text-2xl"
            style={{ listStyleType: "disc" }}
          >
            <li>No more worries about storage & affordability</li>
            <li>Lower your carbon footprint</li>
            <li>Connect with local enthusiasts</li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export default Summary;
