import Header from './Component/Header.js';
import Hero from './Component/Hero.js';
import Summary from './Component/Summary.js';
import Footer from './Component/Footer.js';

function App() {
  return (
    <div>
      <Header/>
      <Hero/>
      <Summary/>
      <Footer/>
    </div>
  )
}

export default App;
