import { useEffect, useState } from "react";
import "./Hero.css";
import Grid from "@mui/material/Grid";
import axios from 'axios';

function Hero() {
  const [email, setEmail] = useState("");
  const [accessKey, setAccessKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const AWS = require("aws-sdk");
  const emailjs = require("emailjs-com");

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await axios.get('0.0.0.0:5000/api')
        console.log('From FetchAPIKey: ', response.data.access_key)
        setAccessKey(response.data.access_key);
        setSecretKey(response.data.secret_key);
      } catch(error) {
        console.error('Error fetching API key: ', error)
      }
    };

    fetchApiKey();
  }, [])
  AWS.config.update({
    accessKeyId: accessKey,
    secretAccessKey: secretKey,
    region: 'us-east-1'
  });

  const s3 = new AWS.S3();

  const emailForm = document.getElementById("email-form");
  const confirmation = document.getElementById("confirmation-msg");
  const repeatedMSG = document.getElementById("repeated-msg");

  const downloadParams = {
    Bucket: "hobbyhop-bubbleio",
    Key: "leads.json",
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can implement the logic for handling the sign-up process with the entered email
    console.log(`User entered email: ${email}`);
    s3.getObject(downloadParams, (err, data) => {
      console.log(accessKey);
      if (err) {
        console.error("Fail to read from AWS S3: ", err);
        alert(
          "There's currently some error with our website. Please try again later!"
        );
      } else {
        const existingContent = JSON.parse(data.Body.toString());

        if (existingContent.leads.some((obj) => obj["email"] === email)) {
          emailForm.style.display = "none";
          repeatedMSG.style.display = "block";
        } else {
          const newData = { email: email };
          existingContent.leads.push(newData);

          const updatedContent = JSON.stringify(existingContent);

          const uploadParams = {
            Bucket: "hobbyhop-bubbleio",
            Key: "leads.json",
            Body: updatedContent,
            ContentType: "application/json",
          };

          s3.upload(uploadParams, (err, data) => {
            if (err) {
              console.error("Fail to write to AWS S3: ", err);
              alert(
                "There's currently some error with our website. Please try again later!"
              );
            } else {
              console.log("JSON file successfully updated! ", data);

              const templateParams = {
                to_email: email,
                from_name: "Hobby Hop",
                message: "Welcome to Hobby Hop!",
              };

              emailjs
                .send(
                  "service_yjh0c8c",
                  "template_wdnain6",
                  templateParams,
                  "W0WaWfpormaZlRMBk"
                )
                .then((response) => {
                  console.log("Email sent:", response);

                  emailForm.style.display = "none";
                  confirmation.style.display = "block";
                })
                .catch((error) => {
                  console.error("Error sending the automated email: ", error);
                  alert(
                    "There's currently some error with our website. Please try again later!"
                  );
                });
            }
          });
        }
      }
    });
  };

  return (
    <div className="background w-full xl:h-[100vh] lg:h-[85vh] md:h-[800px] sm:h-[550px] h-[490px]">
      <div className="landing-page max-w-full md:pt-32 pt-16">
        <div className="content lg:px-20 md:px-12 px-6">
          <Grid container>
            <Grid item xs={1} md={2} lg={4}></Grid>
            <Grid item xs={11} md={10} lg={8}>
              <h1 className="title font-bold text-4xl md:text-5xl lg:text-7xl">
                A sustainable future of hobbies starts with you
              </h1>

              <p className="font-semibold text-white text-base md:text-xl lg:text-2xl lg:mt-20 sm:mt-10 mt-8">
                Discover new hobbies & earn money by renting or selling your
                equipment
              </p>
              <p className="font-semibold text-white text-base md:text-xl lg:text-2xl">
                Be the first to go green & share your passion!
              </p>
              <form className="lg:mt-20 sm:mt-10 mt-8" id="email-form" onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="rounded-lg lg:w-[400px] md:w-[300px] sm:w-[240px] w-[180px]"
                  id="input-email"
                  value={email}
                  onChange={handleInputChange}
                />
                <button className="bg-customOrange" type="submit">
                  Sign Up
                </button>
              </form>
              <p
                id="confirmation-msg"
                className="font-semibold text-white text-base md:text-xl lg:text-2xl lg:mt-20 sm:mt-10 mt-8"
                style={{ display: "none" }}
              >
                Thank you for signing up with us!
              </p>
              <p
                id="repeated-msg"
                className="font-semibold text-white text-base md:text-xl lg:text-2xl lg:mt-20 sm:mt-10 mt-8"
                style={{ display: "none" }}
              >
                Hooray! You are already with us!
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Hero;
