import { useState } from "react";
import Grid from "@mui/material/Grid";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Footer() {
  const [email, setEmail] = useState("");
  const AWS = require("aws-sdk");
  const emailjs = require("emailjs-com");

  AWS.config.update({
    accessKeyId: "AKIA6ODU4WJ7MMQHYCBP",
    secretAccessKey: "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",
  });

  const s3 = new AWS.S3();

  const emailForm = document.getElementById("email-form-footer");
  const confirmation = document.getElementById("confirmation-msg-footer");
  const repeatedMSG = document.getElementById("repeated-msg-footer");

  const downloadParams = {
    Bucket: "hobbyhop-bubbleio",
    Key: "leads.json",
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can implement the logic for handling the sign-up process with the entered email
    console.log(`User entered email: ${email}`);
    s3.getObject(downloadParams, (err, data) => {
      if (err) {
        console.error("Fail to read from AWS S3: ", err);
        alert(
          "There's currently some error with our website. Please try again later!"
        );
      } else {
        const existingContent = JSON.parse(data.Body.toString());

        if (existingContent.leads.some((obj) => obj["email"] === email)) {
          emailForm.style.display = "none";
          repeatedMSG.style.display = "block";
        } else {
          const newData = { email: email };
          existingContent.leads.push(newData);

          const updatedContent = JSON.stringify(existingContent);

          const uploadParams = {
            Bucket: "hobbyhop-bubbleio",
            Key: "leads.json",
            Body: updatedContent,
            ContentType: "application/json",
          };

          s3.upload(uploadParams, (err, data) => {
            if (err) {
              console.error("Fail to write to AWS S3: ", err);
              alert(
                "There's currently some error with our website. Please try again later!"
              );
            } else {
              console.log("JSON file successfully updated! ", data);

              const templateParams = {
                to_email: email,
                from_name: "Hobby Hop",
                message: "Welcome to Hobby Hop!",
              };

              emailjs
                .send(
                  "service_yjh0c8c",
                  "template_wdnain6",
                  templateParams,
                  "W0WaWfpormaZlRMBk"
                )
                .then((response) => {
                  console.log("Email sent:", response);

                  emailForm.style.display = "none";
                  confirmation.style.display = "block";
                })
                .catch((error) => {
                  console.error("Error sending the automated email: ", error);
                  alert(
                    "There's currently some error with our website. Please try again later!"
                  );
                });
            }
          });
        }
      }
    });
  };

  return (
    <div className="px-9 bg-customBlack bg-opacity-90 text-white font-manrope">
      <Grid container spacing={{ xs: 1, md: 2, lg: 2 }}>
        <Grid item lg={2} md={2} display={{ xs: "none", md: "block" }}>
          <img
            className="w-20 lg:w-[180px] md:w-[150px]"
            src="/resource/Logo.png"
            style={{ height: "auto" }}
            alt="logo"
          />
        </Grid>
        <Grid item lg={6} md={4} sm={6} xs={12}>
          <Grid container>
            <Grid item lg={6} xs={12}>
              <p className="lg:mt-12 md:mt-7 mt-4 sm:text-xl text-base font-semibold">
                Find us on social media
              </p>
              <div className="mt-2">
                <Grid container spacing="auto">
                  <Grid item xs="auto">
                    <a
                      className="pr-5"
                      href="https://www.facebook.com/profile.php?id=61556066805071"
                    >
                      <FacebookIcon />
                    </a>
                  </Grid>
                  <Grid item xs="auto">
                    <a
                      className="pr-5"
                      href="https://www.instagram.com/hobby.hop.co/"
                    >
                      <InstagramIcon />
                    </a>
                  </Grid>
                  <Grid item xs="auto">
                    <a href="https://www.linkedin.com/in/hobby-hop-2869502b3/">
                      <LinkedInIcon />
                    </a>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div>
                <p className="lg:mt-12 md:mt-8 mt-4 sm:text-xl text-base font-semibold">
                  Contact us
                </p>
                <p className="mt-2 sm:text-base text-sm font-light">
                  Email: hobbyhop.co@gmail.com
                </p>
                <p className="sm:text-base text-sm font-light">
                  Phone: +1-(438)-869-4005
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className="lg:mt-12 md:mt-7 mt-3 xl:pl-10 pl-0">
            <p className="sm:text-xl text-base font-semibold">
              Let's get in touch!
            </p>
            <form
              className="ml-0 lg:mt-1 mt-1"
              id="email-form-footer"
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                placeholder="Enter your email"
                className="text-black rounded-lg lg:w-[300px] md:w-[300px] w-[180px]"
                id="input-email"
                value={email}
                onChange={handleInputChange}
              />
              <button className="bg-customOrange text-white" type="submit">
                Sign Up
              </button>
            </form>
            <p
              id="confirmation-msg-footer"
              className="font-light text-white sm:text-lg text-sm ml-0 mt-2"
              style={{ display: "none" }}
            >
              Thank you for signing up with us!
            </p>
            <p
              id="repeated-msg-footer"
              className="font-light text-white sm:text-lg text-sm ml-0 mt-2"
              style={{ display: "none" }}
            >
              Hooray! You are already with us!
            </p>
          </div>
        </Grid>
      </Grid>
      <p className="lg:pl-10 md:pt-0 pt-4 pb-1 text-sm font-thin md:text-right text-left">
        &copy; 2024 Hobby Hop &nbsp; &#10022; &nbsp; All rights reserved
      </p>
    </div>
  );
}

export default Footer;
