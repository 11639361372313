import React from 'react';
import Box from '@mui/material/Box';

class Header extends React.Component {
    render() {
        return (
            <Box sx={{
                bgcolor: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>
                <img className='w-20 lg:w-20' src='/resource/Logo.png' style={{height: 'auto',}} alt='logo'/>
            </Box>
        );
    }
}

export default Header;